* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  background-color: #2c3e50;
  color: white;
}

.App {
  text-align: center;
  margin: 25px; /* Adjust margins as needed */
}

.koffertbilde {
  border-radius: 50%;
  height: 150px;
  width: auto;
  margin-top: 30px;

}

.fremsidebilde {
  border-radius: 50%;
  height: 250px;
  width: auto;
  margin-top: 30px;
}

.about {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 100px;
}

.text-box {
  word-wrap: break-word;
  max-width: 500px;
  text-align: center;
}

.text-box p {
  margin-top: 50px;
}

.nav {
  display: flex;
  gap: 2rem;
  padding: 0 1rem;
  margin-top: 10px;
  position: fixed;
  top: 0;
  width: 100%;
  background-color: #2c3e50; /* Ensure the navbar has a background */
  z-index: 1000; /* Make sure it's above other elements */
}

.nav ul {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  gap: 1rem;
  font-size: 1.5rem;
  margin-left: auto;
  margin-right: 25%;
}

.nav a {
  color: inherit;
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  padding: .25rem;
}

.nav li.active {
  text-decoration: underline;
  color: #dddddd;
}

body {
  padding-top: 60px; /* Add padding to avoid content overlapping the fixed navbar */
}

.fremside {
  text-align: center; /* Center the content horizontally */
}

.fremside h1 {
  margin-top: 50px;
}


.fremside h3 {
  margin-top: 5px;
  width: 700px;
  margin-left: auto;
  margin-right: auto;
}

.fremside p {
  margin-bottom: 20px;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
}

.projectview {
  height: 200px;  /* Fixed height for each grid item */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #34495e;
  border-radius: 10px;
  transition: transform 0.3s, box-shadow 0.3s;
  text-align: center;
  margin-top: 150px;
}

.projectview img {
  width: auto;
  height: 100px;
  border-radius: 8px;
  margin-bottom: 15px;
}

.projectview h1 {
  color: #ecf0f1;
  font-size: 1.25rem;
  margin: 0;
  flex-grow: 1;  /* Allow it to grow to fill space */
  overflow: hidden;  /* Truncate overflowing text */
  text-overflow: ellipsis;  /* Display ellipsis for overflow */
  max-height: 3em;  /* Limit the height to 3 lines of text */
}

.projectview p {
  color: #bdc3c7;
  margin: 10px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  max-height: 10em;  /* Limit description height */
}

.projectview a {
  color: #e74c3c;
  font-weight: bold;
  text-decoration: none;
  margin-top: auto;  /* Push to the bottom */
  transition: color 0.3s;
}

.projectview a:hover {
  color: #c0392b;
}

.projectview:hover {
  transform: scale(1.05);
  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.3);
}

a {
  text-decoration: none;
  color: #e74c3c;
}

a:hover {
  color: #c0392b;
}

.prosjektbilde {
  width: 100px;
  height: auto
}


.logoer {
  position: fixed;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 10px;
}

.ikoner {
  height: 50px;
  width: auto;
  margin: 10px;
}

.logoer a {
  color: white;
}


.fremside ul {
  list-style-type: none;
}

.arbeid {
  margin-top: 50px;
}

.imgcompanys {
  height: 150px; 
  width: auto;
  margin-top: 15px;
}

.skill-card-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Auto-fit ensures responsiveness */
  gap: 20px;
  justify-content: center; /* Center grid */
  align-items: center;
  max-width: 1000px; /* Prevents cards from stretching too much */
  margin: 20px auto; /* Centers the entire grid */
}

/* Individual card styling */
.skill-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #34495e;
  border-radius: 10px;
  padding: 15px;
  width: 200px; /* Fixed card width */
  height: 170px; /* Fixed height */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 900px) {
  .skill-card-container {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Adjusts based on space */
  }
}

@media (max-width: 600px) {
  .skill-card-container {
    grid-template-columns: repeat(1, 1fr); /* Single column on small screens */
  }
}

/* Image styling */
.skill-card img {
  height: 75px; /* Adjust the height of the logo */
  width: auto; /* Maintain aspect ratio */
  margin-top: 20px; /* Space between image and text */
  
}

/* Text styling */
.skill-card p {
  margin: 0;
  font-size: 16px; /* Adjust font size as needed */
  color: #ecf0f1;
}

